import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Best Dental Implants Toronto – Dentures Implants | The Smile Stylist" 
    description="Not all dental implants are the same. If you are looking for strong, long lasting implants to hep restore your natural smile and alleviate your pain give us a call." 
    keywords="denture implants, dental implants Toronto, best dental implants Toronto" />
    <main className="site-content" role="main">
    <div className="implants__hero">
      <div className="col-md-6">
        <h1>D E N T A L &nbsp; I M P L A N T S</h1>
        <p>“From professional football and hockey players to your next door neighbour, today’s dental implants are allowing people to get their confidence back after experiencing a broken or missing tooth. With our practically pain-free implants, you will be able to enjoy a permanent fix that will ensure your smile is as good as new!”</p>
        <div className="black">
          <iframe src="https://www.youtube.com/embed/-0lCy5uzvxU?rel=0&amp;controls=0&amp;showinfo=0&amp;ecver=1" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
        </div>
      </div>
    </div>
    <div className="implants__all-on-4">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-8 ao4__left">
            <h2>A L L - ON - 4 &nbsp; T O &nbsp; R E P L A C E &nbsp; D E N T U R E S</h2>
            <p>We are proud to offer our clients the patented all-on-4 implant system that helps alleviate pain and works towards a better overall quality of life. This system utilised 4 permanent implants to replace your dentures or bridges, and provide you with a solution that will last a lifetime. </p>
            <a href="/contact">Book Consult</a>
          </div>
          <div className="col-xs-4 ao4__right"></div>
        </div>
      </div>
    </div>
    <div className="implants__features">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <ul>
              <li className="row">
                <div className="col-xs-4">
                  <img src={ require("../images/desktop/imp1.png" ) } alt="Dental Implants" />
                </div>
                <div className="main-top-imgmain-top-imgcol-xs-8">
                  <h2>3 D &nbsp; C O N E &nbsp; B E A M &nbsp; D E N T I S T R Y</h2>
                  <p>The latest in dental scanning technology, 3D Cone Bone allows Dr Kaloti and his team to see problems that traditional x-rays would miss. Plus with 3D Cone Bone Dentistry, dental implants, and other dental services are more predictable than ever, as the technology allows access to a patient’s dentition and bone structure that is not possible with traditional scans. </p>
                  <a href="/contact">Book Consult</a>
                </div>
              </li>
              <li className="row">
                <div className="col-xs-4"> 
                  <img src={ require("../images/desktop/imp2.png" ) } alt="Dental Implant Treatment" />
                </div>
                <div className="col-xs-8">
                  <h2>S I N G L E &nbsp; D E N T A L &nbsp; I M P L A N T S</h2>
                  <p>Our single dental implant services are perfect for those who are looking to permanently replace a broken tooth or a missing tooth to alleviate gaps or pain in the mouth. With our service, you will be able to find a solution that works, and most importantly, will last a lifetime.</p>
                  <a href="/contact">Book Consult</a>
                </div>
              </li>
              <li className="row">
                <div className="col-xs-4">
                  <img src={ require("../images/desktop/imp3.png" ) } alt="Dental Implant Procedure" />
                </div>
                <div className="col-xs-8">
                  <h2>M I N I &nbsp; I M P L A N T S</h2>
                  <p>Mini implants are the cousin of our single dental implants, but just a little bit smaller. These are perfect for those who are looking to secure a single smaller tooth or wish to limit their dental visits to a single time with local anaesthesia to install the implant. </p>
                  <a href="/contact">Book Consult</a>
                </div>
              </li>
              <li className="row">
                <div className="col-xs-4">
                  <img src={ require("../images/desktop/imp2.png" ) } alt="Dental Implant Background Image" />
                </div>
                <div className="col-xs-8">
                  <h2>C E R A M I C &nbsp; I M P L A N T S</h2>
                  <p>As one of the newest forms of implants, ceramic implants are a great option for those who are looking for a biodegradable option for their implant. This tooth coloured implant option has long been the choice in Europe for implants, and is just now becoming a popular choice in North America. </p>
                  <a href="/contact">Book Consult</a>
                </div>
              </li>

            </ul>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 ba-border">
                <img alt="Dental Implants Before and After" data-id="1" src={ require("../images/desktop/services/Dental Implants 2.png" ) } title="dental implants" className="row-img-basic img-responsive js--img-open-popup" />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 ba-border">
                <img alt="Before and After Dental Implants Image" data-id="2" src={ require("../images/desktop/services/Dental Implants.png" ) } title="Porcelain Veneers" className="row-img-basic img-responsive js--img-open-popup" />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 ba-border">

                <img alt="Full Mouth Reconstruction Before and After" data-id="3" src={ require("../images/desktop/services/Full Mouth Reconstruction.png" ) } title="ClearCorrect Invisible Braces" className="row-img-basic img-responsive js--img-open-popup" />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div className="implants__financing">
      <div className="container">
        <h2>Financing Options & Fees </h2>
        <p>We know that dental implants can be an expensive endeavour, and that is why we are one of the few clinics to offer upfront costs and accurate quotes when discussing options with our clients. We accept a variety of insurances to cover all or a partial amount of the cost, and our front desk staff is always available to answer any questions or concerns you may have about our services and financing options. </p>
      </div>
    </div>
    <div className="implants__test">
      <div className="implants__tslider-outer">
        <div className="container _t-1">
          <img src={ require("../images/desktop/imp-stars.png" ) } alt="Dental Implant Background Image " />
          <p>Best dentist I've been to in years!!</p>
          <p>I came to Dr. Kaloti's office after receiving an offer in the mail for a free consultation. I was very impressed with the time he spent explaining everything that needed to be done regarding dental implants. I have already been through one procedure and will have more done in the fall. All went well and I'm very happy with the work he did. The staff and Dr. Kaloti are very personable and welcoming. A special shout out to Rebecca. Very funny lady! I actually look forward to seeing them again in the fall.</p>
          <span>Abbe Osicka - 9 Jul 2017</span>
        </div>
        <div className="container _t-2">
          <img src={ require("../images/desktop/imp-stars.png" ) } alt="3D Cone Beam Dentistry " />
          <p>I totally agree with the other reviews!</p>
          <p>Best dentist experience! He did my implant 3 years ago and I often forget I have it. The procedure itself was easier than having the tooth pulled and 3 years later it looks and chews like all my other teeth</p>
          <span>Aalayyan- 20 Nov 2016</span>
        </div>
        <div className="container _t-3">
          <img src={ require("../images/desktop/imp-stars.png" ) } alt="Single Dental Implants" />
          <p>I had worn down teeth and spaces between my front teeth</p>
          <p>Had my whole mouth redone with crowns for the old fillings and and implants for the missing teeth. Dr.Kaloti, Stephanie and Penny were amazing! They pretty much held my hand through the whole thing! For a big chicken like me, I felt like I couldn't be in better hands :))</p>
          <span>Suzi - 14 Dec 2016</span>
        </div>
        <div className="container _t-4">
          <img src={ require("../images/desktop/imp-stars.png" ) } alt="Mini Implants" />
          <p>Modern and Thorough</p>
          <p>Theyre very modern and very thorough. You are seen at your appointment time and they will make your smile look good!!</p>
          <span>Jason Jaffet - 18 Oct 2016</span>
        </div>
      </div>

    </div>
    <div className="implants__huge"></div>
  </main>
  </Layout>
)

export default AboutKalotiPage